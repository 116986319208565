<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
import {
  websiteConfig,
} from "./api/index.js";
export default {

  data(){
    return {

    }
  },
  created() {
    websiteConfig().then(res=>{
      document.title = res.data.websiteTitle || 'GP Express'
    })

  },
  mounted() {
  }
}
</script>
<style lang="less">
 @import "./assets/less/common.less";
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
}

#nav {
    padding: 30px;
    a {
        font-weight: bold;
        color: #2c3e50;
        &.router-link-exact-active {
            color: #42b983;
        }
    }
}

body {
    margin: 0;
    font-family: roboto, sans-serif;
    color: #333;
    line-height: 1.5;
    font-weight: 400;
}

.main-width-80 {
    width: 80% !important;
    margin-left: 10% !important;
    margin-right: 10% !important;
    padding-top:15px !important;
}

a {
    color: #3982ea;
}

</style>
