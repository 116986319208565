<template>
  <el-header>

  </el-header>
</template>
<script>
export default {
  data() {
    return {
      menuList: [
        "Send & Receive",
        "Shop",
        "Pay",
        "Financial Services & More",
        "Business Solutions",
        "About Us",
      ],
    };
  },
};
</script>
