<template>
  <el-container>
    <el-header height="50px">
      <div class="home-contact">
        <div class="grid-content bg-purple">
          <a href="" class="flex-row-center">
            <i class="icon-home header-icon"></i> Home
          </a>
        </div>
        <div class="bg-purple grid-border-right" v-if="config.contactUsPageUrl">
          <a :href="config.contactUsPageUrl" class="flex-row-center">
            <i class="icon-phone header-icon"></i>
            Contact Us
          </a>
        </div>
        <div class="bg-purple grid-border-right">
          <router-link to="/express/track" class="flex-row-center">
            <i class="icon-track header-icon"></i>
            Tracking
          </router-link>
        </div>
        <div class="bg-purple">
          <router-link to="/shipping/page" class="flex-row-center">
            <i class="icon-ship header-icon"></i>
            Shipping
          </router-link>
        </div>
      </div>
    </el-header>
    <el-row>
      <div class="footer-main header-menu">
        <a href="/" class="logo">
          <img :src="config.logoUrl" alt="" />
        </a>

        <div class="menu-flex">
          <ul class="list-menu">
            <li
              v-for="(item, index) in menuList"
              :key="index"
              @mouseover="showMenuList(index)"
              @mouseleave="hideMenuList()"
              class="list-menu-li"
              :class="{
                'a-border-bottom': currentIndex == index || pathIds.includes(item.id),
              }"
            >
              <router-link
                :to="`/page/details?pageId=${item.id}`"
                @click="hideMenuList()"
                >{{ item.title }}</router-link
              >
              <ul class="list-menu-item" v-show="currentIndex == index">
                <li v-for="(items, indexs) in item.children" :key="indexs">
                  <!--                  蓝色的标题-->
                  <h2
                    style="cursor: pointer"
                    @click="toDetails(items.id)"
                    v-show="items.children.length == 0"
                  >
                    {{ items.title }}
                  </h2>
                  <!--                  黑色的标题-->
                  <h2 v-show="items.children.length > 0" class="black-title">
                    {{ items.title }}
                  </h2>
                  <h2
                    class="level-title-3"
                    style="cursor: pointer"
                    @click="toDetails(i.id)"
                    v-for="(i, k) in items.children"
                    :key="k"
                  >
                    {{ i.title }}
                  </h2>
                  <!--描述 -->
                  <p>{{ items.summary }}</p>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </el-row>
    <!--    <el-main style="padding-left: 10px 0">-->
    <!--     -->
    <!--    </el-main>-->
    <div class="main-width-80" v-show="pathList&&pathList.length>0">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/home' }">Home</el-breadcrumb-item>
                <el-breadcrumb-item v-for="(item, index) in pathList" :key="index" :to="{ path: `/page/details?pageId=${item.id}` }">{{ item.title }}</el-breadcrumb-item>
            </el-breadcrumb>
            </div>
    <el-row>
      <router-view :key="randomId"></router-view>
    </el-row>
    <main-footer :menuList="menuList" :config="config"></main-footer>
  </el-container>
</template>

<script>
import mainFooter from "./footer.vue";
import mainHeader from "./header.vue";
import {
  menuList,
  websiteOffer,
  websiteConfig,
  websitePage,
} from "../../api/index.js";

export default {
  components: { mainFooter, mainHeader },
  data() {
    return {
      menuList: [],
      currentIndex: -1,
      offter: {},
      fullPath: null,
      config: {},
      pathList:[],
      pathIds:[]
    };
  },
  created() {
    this.menuListData();
    this.websiteOffer();
  },
  computed: {
    randomId() {
      return this.$route.fullPath + new Date().getTime();
    },
    pageId() {
      console.log(this.$route.query.pageId)
     return this.$route.query.pageId;
    },
  },
  mounted() {
    this.fullPath = this.$route.fullPath;
    this.websiteConfig();
    this.getPathList()
  },
  methods: {
    menuListData() {
      menuList().then((res) => {
        this.menuList = res.data;
      });
    },
    showMenuList(index) {
      this.currentIndex = index;
    },
    hideMenuList() {
      this.currentIndex = -1;
    },
    websiteOffer() {
      websiteOffer().then((res) => {
        this.offter = res.data;
      });
    },
    toDetails(pageId) {
      this.$router.push({
        path: "/page/details",
        query: {
          pageId,
        },
      });
      this.hideMenuList();
    },
    websiteConfig() {
      websiteConfig().then((res) => {
        this.config = res.data;
        localStorage.setItem(
          "privacyPolicyPageUrl",
          res.data.privacyPolicyPageUrl
        );
      });
    },
    getPathList() {
      websitePage({ pageId: this.pageId }).then((res) => {
        if(res.data.path){
          this.pathList = res.data.path
          this.pathIds = res.data.path.map((item)=>{
            return item.id
          })

        }
      });
    },
  },
  watch:{
    pageId(val){
     console.log(val)
     if(val){
       this.getPathList()
     }else{
       this.pathList=[]
       this.pathIds = []
     }
    }
  }
};
</script>


<style lang="less" scoped>
@import "../../assets/less/common.less";

/deep/ .el-header {
  background: #111e2b;
  max-height: 50px;
  overflow: hidden;
  color: #ffffff;
}

.home-contact {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  .grid-content {
    border-left: 1px solid #adbccf;
    border-right: 1px solid #adbccf;
  }
  .grid-border-right {
    border-right: 1px solid #adbccf;
  }
  .bg-purple {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    a {
      font-family: roboto, sans-serif;
      text-transform: none;
      color: #adbccf;
      font-size: 13px;
      font-weight: 400;
      padding: 15px;
      background-color: transparent;
      cursor: pointer;
    }
    .icon-home {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("../../assets/img/home.png") no-repeat;
      background-size: cover;
    }
    .icon-phone {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("../../assets/img/phone.png") no-repeat;
      background-size: cover;
    }
    .icon-track {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("../../assets/img/track.png") no-repeat;
      background-size: cover;
    }
    .icon-ship {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url("../../assets/img/ship.png") no-repeat;
      background-size: cover;
    }

    .header-icon {
      margin-right: 10px;
    }
  }
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flex-row-start {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

/deep/ .el-footer {
  padding: 0;
  .el-row {
    background: #e0e3e5;
    padding-top: 10px;
    padding-bottom: 10px;
    .footer-col {
      text-align: left;
      padding-bottom: 40px;
      ul {
        li {
          padding-top: 5px;
        }
      }
    }
  }
  .el-row-bg {
    background: #f2f2f2;
    .ewm {
      width: 90px;
      height: 90px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .col-h2 {
      margin-top: 10px;
    }
    .app-down {
      display: flex;
      img {
        width: 90px;
        height: 30px;
        &:nth-child(1) {
          margin-right: 10px;
        }
      }
    }
    .scroll-top {
      width: 55px;
      height: 55px;
      position: absolute;
      z-index: 100;
      background: #3a81ea;
      border-radius: 50%;
      border: 2px solid #fff;
      top: 0;
      right: -20px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      /deep/ .el-icon-arrow-up {
        font-weight: 600;
      }
      span {
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  }
  .footer-main {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    .flex-1 {
      flex: 1;
      .col-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
        padding: 0 15px;
        font-size: 20px;
        a {
          color: #3982ea;
          font-size: 15px;
          font-weight: 600;
        }
      }
    }
    h2 {
      color: #666;
      font-size: 17px;
      font-weight: 800;
      margin: 0 15px 0 0;
    }
  }
}

.footer-main {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

//菜单导航栏
.header-menu {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  position: relative;
  .logo {
    display: inline-block;
    margin-right: 50px;
    line-height: inherit;
    white-space: nowrap;
    img {
      width: 160px;
      height: 53px;
    }
  }
  .list-menu {
    .list-menu-li {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-bottom: 3px solid transparent;
      height: 100%;
    }
    .a-border-bottom {
      border-color: #e22141;
    }
    li {
      a {
        font-family: roboto, sans-serif;
        font-size: 17px;
        font-weight: 600;
        font-style: normal;
        color: #333;
        text-transform: none;
        background-color: transparent;
      }
    }

    /deep/ .el-row {
      flex: 1;
    }
  }
  .menu-flex {
    flex: 1;
    .list-menu {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .list-menu-item {
        width: 100%;
        background: #f4f5f5;
        padding: 30px;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2) !important;
        border: 0;
        margin-top: 1px;
        z-index: 20;
        position: absolute;
        top: 100%;
        left: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        li {
          width: 20%;
          text-align: left;
          vertical-align: top;
          padding-right: 10px;

          h2,
          a,
          .level-title-3 {
            color: #3a81ea;
            font-size: 17px;
            font-weight: 600;
            padding-bottom: 10px;
          }
          .level-title-3 {
            padding-bottom: 5px;
          }
          .black-title {
            color: #000;
            font-size: 17px;
            font-weight: 600;
            padding-bottom: 10px;
          }
          p {
            color: #333;
            font-weight: 600;
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
