<template>
  <el-footer style="min-height:60px;margin-top:50px;">
    <!-- <el-row>
      <div class="footer-main flex-row-start">
        <h2>Recently Visited</h2>
        <div class="flex-1">
          <ul style="display: flex; flex-wrap: wrap">
            <li class="col-auto">
              <a href="">Country-specific requirements – U</a>
            </li>
            <li class="col-auto">
              <a href="">Country-specific requirements – S</a>
            </li>
            <li class="col-auto">
              <a href="">Country-specific requirements – C</a>
            </li>
            <li class="col-auto">
              <a href="">Contact Us</a>
            </li>
            <li class="col-auto">
              <a href=""
              >LOG TOP issues Zodiac Pig stamps, completing 12-year series</a
              >
            </li>
          </ul>
        </div>
      </div>
    </el-row> -->
    <el-row class="el-row-bg">
      <div class="footer-main flex-row-start" style="position: relative">
        <a class="scroll-top" href="#">
          <i class="el-icon-arrow-up"></i>
          <span>Top</span>
        </a>
        <el-row :gutter="30" class="el-row-bg" style="width: 100%">
          <el-col :span="4" v-for="(item, index) in menuList" :key="index" >
            <div
                class="footer-col"
                v-if="index<5"
            >
              <h2 @click="toDetails(item.id)" style="cursor: pointer">{{ item.title }}</h2>

              <ul v-for="(items, j) in item.children" :key="j">
                <li @click="toDetails(items.id)" style="cursor: pointer">{{ items.title }}</li>
              </ul>
            </div>
          </el-col>
          <el-col :span="4">
            <div>
              <h2>Connect with us</h2>
              <ul>
                <li></li>
              </ul>
            </div>

            <h2 class="col-h2">Download LOG TOP</h2>
            <h2 style="margin-bottom:10px">Mobile App</h2>
<!--            <img-->
<!--                class="ewm"-->
<!--                src="https://www.singpost.com/sites/default/files/2021-11/DownloadAppSP.png"-->
<!--                alt=""-->
<!--            />-->
            <div class="app-down">
              <img
                  src="https://aa-r0405-com.oss-cn-beijing.aliyuncs.com/app-store1.png"
                  alt=""
              />
              <img
                  src="https://aa-r0405-com.oss-cn-beijing.aliyuncs.com/google-play1.png"
                  alt=""
              />
            </div>
          </el-col>
        </el-row>

      </div>
    </el-row>
   <el-row class="el-row-bg" style="padding-top:0;">
     <div class="footer-main">
       <p class="copyright">
         <span class="bah">{{config.copyWright}}</span>
         <span>
            <a :href="config.privacyPolicyPageUrl">Privacy Policy</a>
            <a :href="config.onlineSecurityPageUrl">Online Security & You</a>
      </span>
       </p>

     </div>
   </el-row>
  </el-footer>
</template>
<script>

export default {
  props:{
    config:{

    },
    menuList:{
      type:Array
    }
  },
  data() {
    return {

    }
  },
  methods:{
    toDetails(id){
      this.$router.push({
        path:'/page/details',
        query:{
          pageId:id
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
 @import "../../assets/less/common.less";
.footer-main {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flex-row-start {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

/deep/ .el-footer {
  padding: 10px;
  min-height:60px;

  .el-row {
    background: #e0e3e5;
    padding-top: 10px;
    padding-bottom: 10px;

    .footer-col {
      text-align: left;
      padding-bottom: 20px;
      h2{
        cursor: pointer;
      }
      ul {
        li {
          padding-top: 5px;
          cursor:pointer;
        }
      }
    }
  }

  .el-row-bg {
    background: #f2f2f2;

    .ewm {
      width: 90px;
      height: 90px;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .col-h2 {
      margin-top: 10px;
    }

    .app-down {
      display: flex;
      margin-top:10px;
      img {
        width: 90px;
        height: 30px;

        &:nth-child(1) {
          margin-right: 10px;
        }
      }
    }

    .scroll-top {
      width: 55px;
      height: 55px;
      position: absolute;
      z-index: 100;
      background: #3a81ea;
      border-radius: 50%;
      border: 2px solid #fff;
      top: 0;
      right: -20px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      /deep/ .el-icon-arrow-up {
        font-weight: 600;
      }

      span {
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }
  }

  .footer-main {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;

    .flex-1 {
      flex: 1;

      .col-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
        padding: 0 15px;
        font-size: 20px;

        a {
          color: #3982ea;
          font-size: 15px;
          font-weight: 600;

         
        }
      
      }
    }

    h2 {
      color: #666;
      font-size: 17px;
      font-weight: 800;
      margin: 0 15px 0 0;
      cursor: pointer;
    }
  }
}
.copyright {
  border-top: 1px solid #cecece;
  padding: 15px 0;
  font-family: helvetica, arial;
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  .bah{
    color:#333;
    font-size: 12px;
  }
  a{
    color:#666;
    font-size: 12px;
    padding-right:10px;
  }
}
</style>
