import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '../components/layout/index.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home',
    component: layout,
    children: [
      {
        path: 'home',
        name: 'home',
        component: () => import('../views/Home.vue')
      }
    ]
  },
  {
    path: '/page',
    name: 'page',
    component: layout,
    children: [
      {
        path: 'details',
        name: 'pageDetails',
        component: () => import('../views/pageDetails.vue')
      }
    ]
  },
  {
    path: '/shop',
    name: 'shop',
    component: layout,
    children: [
      {
        path: 'shop',
        name: 'shop',
        component: () => import('../views/shop.vue')
      }
    ]
  },
  {
    path: '/more',
    name: 'moreServices',
    component: layout,
    children: [
      {
        path: 'services',
        name: 'services',
        component: () => import('../views/more-services.vue')
      }
    ]
  },
  {
    path: '/about',
    name: 'aboutUs',
    component: layout,
    children: [
      {
        path: 'us',
        name: 'us',
        component: () => import('../views/about-us.vue')
      }
    ]
  },
  {
    path: '/express',
    name: 'express',
    component: layout,
    children: [
      {
        path: 'track',
        name: 'track',
        component: () => import('../views/expressTrack.vue'),
      }
    ]
  },
  {
    path: '/shipping',
    name: 'shipping',
    component: layout,
    children: [
      {
        path: 'page',
        name: 'orderPage',
        component: () => import('../views/orderPage.vue'),
        
      }
    ]
  },
]

const router = new VueRouter({
  routes
})

export default router
