import request from '@/utils/request'
function setObj(data){
    var str='?'
    for(var key in data){
        var val=key+'='+data[key]+'&'
        str +=val
    }
    var result =str.substr(0,str.length-1);
    return result

}
//header菜单栏
export function menuList() {
    return request({
        url: '/website/menu',
        method: 'get'
    })
}
//产品
export function productList() {
    return request({
        url: '/website/product',
        method: 'get'
    })
}
//config
export function websiteConfig() {
    return request({
        url: '/website/config',
        method: 'get'
    })
}
//banner
export function banner(){
    return request({
        url:'/website/banner',
        method:'get'
    })
}
///website/offer
export function websiteOffer() {
    return request({
        url: '/website/offer',
        method: 'get'
    })
}
///website/page
export function websitePage(data) {
    return request({
        url: '/website/page',
        method: 'post',
        data
    })
}
//
export function expressTrack(data) {
    return request({
        url: '/express/track2',
        method: 'post',
        data
    })
}
export function expressNew() {
    return request({
        url: '/website/news',
        method: 'post',

    })
}
export function pageChildren(data) {
    return request({
        url: '/website/page-children',
        method: 'post',
        data
    })
}
